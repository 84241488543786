<template>
  <content-items-edit
    content-type="files"
    content-type-singular="file"
    content-name="Files"
    content-name-singular="File"
  />
</template>

<script>
import ContentItemsEdit from '@/views/content-item/content-items-edit/ContentItemsEdit.vue'

export default {
  components: {
    ContentItemsEdit,
  },
}
</script>

<style scoped>

</style>
